import { useCallback, useState } from 'react';
import { useAuthContext } from '@indomita-react/auth-provider';
import { useTranslations } from '@pepita-react/i18n';
import { useAtom, useSetAtom } from 'jotai';

import type { VisitRequestSent } from '../atoms/visitRequestSentAtom';
import { visitRequestsSentAtom } from '../atoms/visitRequestSentAtom';

import { authModalAtom } from 'src/components/AuthModal/atoms/authModalAtom';

import { isFeatureEnabled } from 'src/config/features-toggle';

import { RegisterType } from 'src/types/auth';
import type { RealEstateKey } from 'src/types/real-estate';

export const useVisitRequest = (
  isVisitBookable?: boolean,
  realEstate?: RealEstateKey,
  onClose?: () => void,
  onSubmit?: ({ response, data, visitRequestData }) => void,
  withLandingDialog?: boolean,
  withVisit?: boolean
) => {
  const { trans } = useTranslations();

  const { user } = useAuthContext();

  const setAuthModal = useSetAtom(authModalAtom);

  const [visitRequestsSent, setVisitRequestsSent] = useAtom(
    visitRequestsSentAtom
  );

  const currentVisitSent = visitRequestsSent.find(
    (item) => item.id === realEstate?.id && item.type === realEstate.type
  );

  // checks if visit request is enabled on product
  const isVisitRequestEnabled =
    isFeatureEnabled('VISIT_REQUEST_ENABLED') && isVisitBookable;

  const isVisitJustSent = Boolean(currentVisitSent);

  /**
   * opens the landing modal instead of the visit request modal
   * in case of visit just sent|retrieved from socials
   */
  const [showLanding, setShowLanding] = useState<boolean>(
    Boolean(withLandingDialog && (isVisitJustSent || withVisit))
  );

  const [step, setStep] = useState<'availability' | 'contact'>('availability');

  const handleBackClick = useCallback(() => {
    setStep('availability');
  }, []);

  const updateVisitRequestsSent = useCallback(
    (showContactBox: boolean) => {
      setVisitRequestsSent((oldState: VisitRequestSent[]) => {
        // as it's possible to make several consecutive visit requests for the same real estate
        // and showContactBox value can be edited outside the visit component,
        // for example on detail contact form, first delete the current element
        // and then add it again with the updated values
        const filteredOldState = oldState.filter(
          (item) =>
            item.id !== realEstate?.id ||
            (item.id === realEstate.id && item.type !== realEstate.type)
        );

        if (realEstate) {
          return filteredOldState.concat({
            id: realEstate.id,
            type: realEstate.type,
            showContactBox,
          });
        }

        return [];
      });
    },
    [setVisitRequestsSent, realEstate]
  );

  const handleSuccess = useCallback(
    ({ response, data, visitRequestData }) => {
      if (onSubmit) {
        onSubmit({ response, data, visitRequestData });
      }

      updateVisitRequestsSent(true);
      setStep('contact');

      if (withLandingDialog) {
        setShowLanding(true);
        setStep('availability');
      }

      if (
        isFeatureEnabled('AUTH_POST_CONTACT') &&
        !user &&
        response.registerType !== RegisterType.UNREGISTERED
      ) {
        setAuthModal({
          open: true,
          data: {
            email: data.email,
            additionalTrackingData: {
              'Entry Point': 'Modale Login Richiesta Contatto',
            },
            alertMessage: trans('lbl_new_visit_request_sent', {
              params: ['', ''],
            }),
          },
          onClose: () => {
            setAuthModal({ open: false });
            onClose?.();
          },
          onSuccess: () => {
            setAuthModal({ open: false });
            onClose?.();
          },
          showAdvantageInfo: true,
        });
      }

      // close the component only without landing dialog
      if (!withLandingDialog && onClose) {
        onClose();
      }
    },
    [
      onSubmit,
      updateVisitRequestsSent,
      withLandingDialog,
      user,
      onClose,
      setAuthModal,
      trans,
    ]
  );

  const hideVisitRequestSentBox = useCallback(() => {
    updateVisitRequestsSent(false);
  }, [updateVisitRequestsSent]);

  return {
    step,
    setStep,
    isVisitRequestEnabled,
    isVisitJustSent,
    visitRequestsSent,
    handleSuccess,
    visitRequestSentBox: Boolean(currentVisitSent?.showContactBox),
    hideVisitRequestSentBox,
    showLanding,
    setShowLanding,
    handleBackClick,
  };
};
