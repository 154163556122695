import { useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';

import { PepitaIcon } from 'src/libs/ui/pepita-icon';

import type { PhoneTypes } from 'src/types/real-estate';

import css from './styles.module.scss';

enum Styles {
  phone = 'in-agencyPhone',
  green = 'in-agencyPhone--green',
  icon = 'in-agencyPhone__icon',
}

interface AgencyPhoneProps {
  type: PhoneTypes;
  phoneNumber?: string;
  isPhoneVisible?: boolean;
  onShowPhone?: () => void;
  onPhoneClick?: () => void;
}

export function AgencyPhone({
  type,
  phoneNumber,
  isPhoneVisible,
  onShowPhone,
  onPhoneClick,
}: AgencyPhoneProps) {
  const { trans } = useTranslations();
  const [showPhone, setShowPhone] = useState(isPhoneVisible || false);

  if (!phoneNumber) {
    return null;
  }

  if (type === 'green-phone' || phoneNumber.startsWith('800')) {
    return (
      <p>
        <a
          className={clsx(css[Styles.phone], css[Styles.green])}
          href={`tel:${phoneNumber}`}
        >
          <PepitaIcon name="phone" customClass={css[Styles.icon]} />
          {phoneNumber}
        </a>
      </p>
    );
  }

  return (
    <div data-type={type}>
      {!showPhone ? (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
        <a
          role="button"
          className={css[Styles.phone]}
          onClick={(e) => {
            e.preventDefault();
            setShowPhone(true);
            onShowPhone?.();
          }}
        >
          <PepitaIcon name="phone" customClass={css[Styles.icon]} />
          {trans('lbl_show_phone')}
        </a>
      ) : (
        <a
          className={clsx(css[Styles.phone])}
          href={`tel:${phoneNumber}`}
          onClick={onPhoneClick}
        >
          <PepitaIcon name="phone" customClass={css[Styles.icon]} />
          {phoneNumber}
        </a>
      )}
    </div>
  );
}
