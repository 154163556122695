import type { BaseSyntheticEvent, ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import type { UserData } from '@indomita-react/auth-provider';
import { useAuthContext } from '@indomita-react/auth-provider';
import { useBreakpoint } from '@pepita-react/use-breakpoint';
import { useRouter } from 'next/router';

import { isFeatureEnabled } from 'src/config/features-toggle';

import type { CONTRACT } from 'src/constants/real-estate';

import { useVisitRequestTracking } from './useVisitRequestTracking';

import type {
  ContactFormData,
  ContactFormInitialData,
  SendVisitRequestData,
} from 'src/libs/contact';
import {
  buildInitialData,
  saveSuggested,
  sendLead,
  trackLeadConversion,
  useContactFormSchema,
  useContactFormStatus,
} from 'src/libs/contact';

import { ContactSubType } from 'src/tracking/utils/enums';

import type { Agency } from 'src/types/agency';
import type { Advertiser, Property } from 'src/types/real-estate';
import type { ListProperty } from 'src/types/real-estate-list';
import type { PropertyLite } from 'src/types/real-estate-lite';
import type { SendLeadResponse } from 'src/types/send-lead';
import type { Language } from 'src/types/translations';

import {
  getValidationErrors,
  isEmail,
  isFieldInvalid,
  validateField,
  validateFields,
} from 'src/utils/form-validators';

const socialsEnabledOnProduct = isFeatureEnabled('SOCIALS_ENABLED');

interface VisitRequestFormProps {
  initialData: ContactFormInitialData;
  visitRequestData: SendVisitRequestData;
  advertiser?: Advertiser;
  contract?: CONTRACT;
  properties?: (Property | ListProperty | PropertyLite)[];
  agency?: Agency;
  onSubmit?: ({
    response,
    data,
    visitRequestData,
  }: {
    response: SendLeadResponse;
    data: ContactFormData;
    visitRequestData: SendVisitRequestData;
  }) => void;
  onError?: () => void;
}

export function useVisitRequestForm({
  initialData,
  visitRequestData,
  advertiser,
  contract,
  properties,
  agency,
  onSubmit,
  onError,
}: VisitRequestFormProps) {
  const { user } = useAuthContext();
  const { locale, defaultLocale } = useRouter();

  const isUserEmailValid = user?.email
    ? isEmail<UserData>('')(user, 'email').valid
    : false;

  const [data, setData] = useState<ContactFormData>(buildInitialData(user));

  useEffect(() => {
    setData(buildInitialData(user));
  }, [user]);

  const [status, setStatus] = useContactFormStatus();

  const schema = useContactFormSchema();

  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });

  const { agencyId, listingId, listingType, isUnicredit, source } = initialData;

  const { trackRequestVisitSubmit } = useVisitRequestTracking(listingId ?? 0);

  async function handleSuccess(response: SendLeadResponse) {
    if (socialsEnabledOnProduct && !user) {
      // reset privacy value for unlogged user
      setData({ ...data, privacy: false });
    }

    trackLeadConversion({
      data: {
        ...initialData,
        contract,
        advertiser,
        properties,
        agency,
      },
      isUnicredit,
    });

    setStatus({
      type: 'success',
      message: '',
    });

    trackRequestVisitSubmit({
      contactId: response.contactId,
      email: data.email,
      phone: data.phone,
      contactSubtype:
        visitRequestData.visitType === 'real'
          ? ContactSubType.ON_SITE
          : ContactSubType.REMOTE,
    });

    if (onSubmit) {
      onSubmit({ response, data, visitRequestData });
    }
  }

  function handleError() {
    setStatus({
      type: 'error',
      errors: [],
    });

    if (onError) {
      onError();
    }
  }

  function handleSubmit() {
    if (status.type === 'loading') return;

    const errors = validateFields(data, schema);

    if (errors) {
      setStatus({
        type: 'error',
        errors,
      });

      return;
    }

    setStatus('loading');

    const promise = sendLead({
      ...(data as ContactFormData),
      agencyId,
      listingId,
      listingType,
      isUnicredit,
      source,
      mobile: maxScreenWidthSm,
      lang: (locale || defaultLocale) as Language,
      ...visitRequestData,
    });

    promise.then(handleSuccess, handleError);

    /** Save suggested searches
     * listingId is possible undefined only on generic agency contact
     * in this case we don't call suggested searches because its mandatory for the call
     *  @see: https://gitlab.pepita.io/immobiliare/site-v7/-/blob/develop/site/src/Immobiliare/AppBundle/Controller/SearchController.php#L279
     */
    if (listingId) {
      saveSuggested({
        listingId,
        listingType,
        email: data.email,
      });
    }
  }

  const handleValidateTextInput =
    (name: keyof ContactFormData) => (evt: BaseSyntheticEvent) => {
      const value = evt.target.value;

      const error = validateField(name, data, schema);

      if (status.type === 'error') {
        const errors = validateFields(data, schema);
        const prev = new Set(status.errors.map((e) => e.path));

        if (value && errors) {
          setStatus({
            type: 'error',
            errors: (error || []).concat(
              errors.filter((e) => prev.has(e.path))
            ),
          });
        }
      } else if (value && error) {
        setStatus({
          type: 'error',
          errors: error,
        });
      }
    };

  function isNotValid(name: keyof ContactFormData) {
    return status.type === 'error' && isFieldInvalid(status.errors, name);
  }

  function getErrorMessage(name: keyof ContactFormData): string | undefined {
    return (
      (isNotValid(name) &&
        status.type === 'error' &&
        getValidationErrors(status.errors, name)) ||
      undefined
    );
  }

  const handleChange =
    (name: keyof ContactFormData) =>
    (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value =
        evt.target.type === 'checkbox'
          ? (evt.target as HTMLInputElement).checked
          : evt.target.value;

      const newData = {
        ...data,
        [name]: value,
      };

      setData(newData);

      // We only remove the errors during change
      if (status.type === 'error') {
        const errors = validateFields(newData, schema);

        if (!errors) {
          setStatus('ok');
        } else {
          const prev = new Set(status.errors.map((e) => e.path));

          setStatus({
            type: 'error',
            errors: errors.filter((e) => prev.has(e.path)),
          });
        }
      }
    };

  return {
    status,
    handleSubmit,
    handleChange,
    handleValidateTextInput,
    isNotValid,
    isUserEmailValid,
    getErrorMessage,
    data,
    visitRequestData,
  };
}
